<template>
  <div class="">
    <h1>REALTORS® Relief Foundation - Contribution Form</h1>
  </div>
  <div class="rrfContainer">
    <div class="rrfImage">
      <img src="../assets/rrf_image.png">
    </div>
    <div class="rrfText">
      <span>The REALTORS® Relief Foundation was established to provide to needy victims of disasters (including, but not limited to affected relief and rescue workers), and their families, assistance with housing-related needs arising out of such disasters, and for other charitable purposes permitted under Section 501(c)(3) of the Internal Revenue Code. <b>The Foundation may elect to provide assistance primarily or solely to victims who are members of the National Association of Realtors®</b>. Once you have filled in all your contribution information, please scroll to the bottom of the form and click "Submit Contribution" to continue the process. Transmission of your contribution and credit card information may take a few minutes; please do not click the Submit Contribution button more than once or you will receive an error message.</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntroDiv'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rrfContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1em;
}

.rrfText {
  padding-left: 20px;
}

h1 {
  font-weight: bolder;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
