<template>
  <Intro />
  <ContributeForm />
  <FinInfo />
</template>

<script>
import Intro from './components/Intro.vue'
import ContributeForm from './components/ContributeForm.vue'
import FinInfo from './components/FinInfo.vue'

export default {
  name: 'App',
  components: {
    Intro,
    ContributeForm,
    FinInfo
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
  /* margin-left: 20em;
  margin-right: 20em; */
}
</style>
