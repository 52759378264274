<template>
  <div class="finInfo mt-5 mb-5">
    <p>
      Financial information about REALTORS® Relief Foundation and a description of its charitable mission and programs can be obtained by writing to: REALTORS® Relief Foundation,430 N Michigan Ave, Chicago, IL 60611 or calling (312) 329-8200. Some states require that special notices be included with each solicitation. If you are a resident of one of these states, you may obtain financial information, solicitation and annual reports, and/or registration directly from the state agency:
    </p>
    <p>
      <u>COLORADO</u> - Colorado residents may obtain copies of registration and financial documents from the Secretary of State by calling (303) 894-2680 or at <a href="http://www.sos.state.co.us/">http://www.sos.state.co.us/</a> re: Reg. No. 20103020665.
    </p>
    <p>
      <u>FLORIDA</u> - A COPY OF THE OFFICIAL REGISTRATION AND FINANCIAL INFORMATION MAY BE OBTAINED FROM THE DIVISION OF CONSUMER SERVICES BY CALLING TOLL-FREE 1-800-HELP-FLA OR ONLINE AT <a href="www.FloridaConsumerHelp.com">www.FloridaConsumerHelp.com</a>. REGISTRATION DOES NOT IMPLY ENDORSEMENT, APPROVAL, OR RECOMMENDATION BY THE STATE. REGISTRATION #: CH13591.
    </p>
    <p>
      <u>GEORGIA</u> - A full and fair description of the programs and activities of REALTORS® Relief Foundation of the United States and its financial statement, which is consistent with the financial statement required to be field with the Secretary of State, are available upon request at the office, phone number, and website indicated above.
    </p>
    <p>
      <u>MARYLAND</u> - A copy of the current financial statement of REALTORS® Relief Foundation of the United States is available by writing to the address or by calling the phone number listed above. Documents and information submitted under the Maryland Solicitations Act are also available, for the cost of postage and copies, from the Maryland Secretary of State, State House, Annapolis, MD 21401, (410) 974-5534.
    </p>
    <p>
      <u>MISSISSIPPI</u> - The official registration and financial information of REALTORS® Relief Foundation of the United States may be obtained from the Mississippi Secretary of State's Office by calling 1-888-236-6167. Registration with the Secretary of State does not imply endorsement by the secretary of state.
    </p>
    <p>
      <u>NEW JERSEY</u> - INFORMATION FILED WITH THE ATTORNEY GENERAL CONCERNING THIS CHARITABLE SOLICITATION AND THE PERCENTAGE OF CONTRIBUTIONS RECEIVED BY THE CHARITY DURING THE LAST REPORTING PERIOD THAT WERE DEDICATED TO THE CHARITABLE PURPOSE MAY BE OBTAINED FROM THE ATTORNEY GENERAL OF THE STATE OF NEW JERSEY BY CALLING (973) 504-6215 AND IS AVAILABLE ON THE INTERNET AT <a href="http://www.state.nj.us/lps/ca/charity/chardir.htm">http://www.state.nj.us/lps/ca/charity/chardir.htm</a>. REGISTRATION WITH THE ATTORNEY GENERAL DOES NOT IMPLY ENDORSEMENT.
    </p>
    <p>
      <u>NEW YORK</u> - UPON REQUEST TO THE ABOVE-LISTED ADDRESS, A COPY OF THE LATEST ANNUAL REPORT CAN BE OBTAINED FROM REALTORS® Relief Foundation OF THE UNITED STATES, OR FROM THE OFFICE OF THE ATTORNEY GENERAL BY WRITING THE CHARITIES BUREAU, 120 BROADWAY, NEW YORK, NY 10271. YOU MAY OBTAIN INFORMATION ON CHARITABLE ORGANIZATIONS FROM THE NEW YORK STATE OFFICE OF THE ATTORNEY GENERAL AT <a href="WWW.CHARITIESNYS.COM">WWW.CHARITIESNYS.COM</a> OR (212) 416-8401.
    </p>
    <p>
      <u>NORTH CAROLINA</u> - Financial information about this organization and a copy of its license are available from the State Solicitation Licensing Branch 1-888-830-4989 (within North Carolina) or (919) 814-5400 (outside of North Carolina). The license is not an endorsement by the State.
    </p>
    <p>
      <u>PENNSYLVANIA</u> - The official registration and financial information of REALTORS® Relief Foundation may be obtained from the Pennsylvania Department of State by calling toll free, within Pennsylvania 1-(800)-732-0999. Registration does not imply endorsement.
    </p>
    <p>
      <u>TEXAS</u> - The Secretary of State has on file important information about persons that seek contributions in the name of veterans, and the number to call about that information is the Solicitation Information Hotline (1-800-648-9642).
    </p>
    <p>
      <u>VIRGINIA</u> - Financial statements are available upon request from the State Office of Consumer Affairs in the Department of Agriculture and Consumer Services, P.O. Box 1163, Richmond, VA 23219; 1-804-786-1343
    </p>
    <p>
      <u>WASHINGTON</u> - The notice of solicitation required by the state office of Consumer Affairs is on file with the Washington Secretary of State, and information relating to financial affairs of REALTORS® Relief Foundation is available from the Secretary of State, and the toll-free number for Washington residents is 800-332-4483.
    </p>
    <p>
      <u>WEST VIRGINIA</u> - West Virginia residents may obtain a summary of the registration and financial documents from the Secretary of State, State Capitol, Charleston, West Virginia 25305. Registration does not imply endorsement.
    </p>
    <p>
      <u>WISCONSIN</u> - A financial statement of the charitable organization disclosing assets, liabilities, fund balances, revenue, and expenses for the preceding fiscal year will be provided to any person upon request.
    </p>
    <p>
      You are under no obligation to contribute to REALTORS® Relief Foundation. Contributions made to REALTORS® Relief Foundation may be tax deductible pursuant to the provisions of the Internal Revenue Code of 1986, specifically, 26 U.S.C. § 170(c) to the extent that no goods or services were provided in connection with the contribution. All contributions are for general support and shall not be subject to any restrictions unless REALTORS® Relief Foundation has agreed to such restrictions in a written agreement with a donor.
    </p>
    <p>
      Registration in the above states does not imply endorsement, approval, or recommendation of REALTORS® Relief Foundation of the United States by the states.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
