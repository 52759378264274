<template>
  <div class="mt-4">
    <form name="contributeForm">
      <div class="contributorInformation">
        <h3 class="border-bottom mb-4">Contributor Information</h3>
        <div class="form-group row">
          <label for="contributorName" class="col-sm-3 col-form-label"><span class="text-danger">*</span>Contributor Name or Business Name:</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="contributorName" :required="true">
          </div>
        </div>
        <div class="form-group row">
          <label for="contributorMem" class="col-sm-3 col-form-label">Contribution Made in Memory of:</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="contributorMem">
          </div>
        </div>
        <div class="form-group row">
          <label for="contributorHon" class="col-sm-3 col-form-label">Contribution Made in Honor of:</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="contributorHon">
          </div>
        </div>
        <div class="form-group row">
          <label for="contributorEmail" class="col-sm-3 col-form-label"><span class="text-danger">*</span>Email Address:</label>
          <div class="col-sm-6">
            <input type="email" class="form-control" id="contributorEmail" :required="true">
          </div>
        </div>
      </div>
      <div class="paymentInformation">
        <h3 class="border-bottom mb-4">Payment Information</h3>
        <div class="form-group row">
          <label for="paymentAmount" class="col-sm-3 col-form-label"><span class="text-danger">*</span>Payment Amount:</label>
          <div class="col-sm-1">
            <input type="tel" class="form-control" id="paymentAmount" placeholder="0.00" v-model="price" @input="formatPrice" :required="true">
          </div>
        </div>
        <div class="form-group row">
          <label for="ccType" class="col-sm-3 col-form-label"><span class="text-danger">*</span>Credit Card Type:</label>
          <div class="col-sm-8">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="ccType" id="Visa" value="Visa">
              <label class="form-check-label" for="Visa">Visa</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="ccType" id="MasterCard" value="MasterCard">
              <label class="form-check-label" for="MasterCard">Master Card</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="ccType" id="AmericanExpress" value="AmericanExpress">
              <label class="form-check-label" for="AmericanExpress">American Express</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="ccType" id="Discover" value="Discover">
              <label class="form-check-label" for="Discover">Discover</label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="ccNumber" class="col-sm-3 col-form-label"><span class="text-danger">*</span>Credit Card Number:</label>
          <div class="col-sm-2">
            <input type="text" class="form-control" name="ccNumber" placeholder="0000 0000 0000 0000" size="18" id="ccNumber" v-model="creditCardNumber" @input="formatCreditCardNumber" minlength="19" maxlength="19">
          </div>
        </div>
        <div class="form-group row">
          <label for="expireDate" class="col-sm-3 col-form-label"><span class="text-danger">*</span>Expiration Date:</label>
          <div class="col-sm-1">
            <input type="text" class="form-control" name="expireDate" placeholder="MM/YY" size="6" id="expireDate" v-model="expiryDate" @input="formatExpiryDate" minlength="5" maxlength="5">
          </div>
        </div>
        <div class="form-group row">
          <label for="ccName" class="col-sm-3 col-form-label"><span class="text-danger">*</span>Name as Appears on Credit Card:</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="ccName" :required="true">
          </div>
        </div>
        <div class="form-group row">
          <label for="billAddress1" class="col-sm-3 col-form-label"><span class="text-danger">*</span>Billing Address 1:</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="billAddress1" :required="true">
          </div>
        </div>
        <div class="form-group row">
          <label for="billAddress2" class="col-sm-3 col-form-label">Billing Address 2:</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="billAddress2">
          </div>
        </div>
        <div class="form-group row">
          <label for="billCity" class="col-sm-3 col-form-label"><span class="text-danger">*</span>Billing City:</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="billCity" :required="true">
          </div>
        </div>
        <div class="form-group row">
          <label for="ccState" class="col-sm-3 col-form-label"><span class="text-danger">*</span>State/Providence:</label>
          <div class="col-sm-2">
            <select class="custom-select" :value="value" @change="select" id="ccState">
              <option value="" disabled>Select a state/province</option>
              <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="ccZip" class="col-sm-3 col-form-label"><span class="text-danger">*</span>Zip Code/Postal Code:</label>
          <div class="col-sm-2">
            <input type="text" class="form-control" id="ccZip" :required="true">
          </div>
        </div>
        <div class="form-group row">
          <label for="ccCountry" class="col-sm-3 col-form-label"><span class="text-danger">*</span>Country:</label>
          <div class="col-sm-2">
            <select class="custom-select" v-model="selectedCountry" id="ccCountry">
              <option value="" disabled>Select a country</option>
              <option v-for="country in countries" :key="country.code" :value="country.code">{{ country.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="requiredField" class="col-sm-3 col-form-label"><span class="text-danger">*</span><b>Indicates a Required Field</b></label>
      </div>
      <button type="submit" class="btn btn-primary">Submit Contribution</button>
    </form>
  </div>
</template>

<script>
import NarPaymentClient from 'nar-payment-client';

export default {
  data() {
    return {
      countries: [
        { code: 'AD', name: 'Andorra' },
        { code: 'AR', name: 'Argentina' },
        { code: 'AU', name: 'Australia' },
        { code: 'AT', name: 'Austria' },
        { code: 'BS', name: 'Bahamas' },
        { code: 'BH', name: 'Bahrain' },
        { code: 'BE', name: 'Belgium' },
        { code: 'BZ', name: 'Belize' },
        { code: 'BM', name: 'Bermuda' },
        { code: 'BO', name: 'Bolivia' },
        { code: 'BR', name: 'Brazil' },
        { code: 'VG', name: 'British Virgin Islands' },
        { code: 'BG', name: 'Bulgaria' },
        { code: 'CA', name: 'Canada' },
        { code: 'KY', name: 'Cayman Islands' },
        { code: 'CL', name: 'Chile' },
        { code: 'CN', name: 'China' },
        { code: 'CO', name: 'Colombia' },
        { code: 'CK', name: 'Cook Islands' },
        { code: 'CR', name: 'Costa Rica' },
        { code: 'HR', name: 'Croatia' },
        { code: 'CY', name: 'Cyprus' },
        { code: 'CZ', name: 'Czech Republic' },
        { code: 'DK', name: 'Denmark' },
        { code: 'DO', name: 'Dominican Republic' },
        { code: 'EC', name: 'Ecuador' },
        { code: 'EG', name: 'Egypt' },
        { code: 'SV', name: 'El Salvador' },
        { code: 'FJ', name: 'Fiji' },
        { code: 'FI', name: 'Finland' },
        { code: 'FR', name: 'France' },
        { code: 'PF', name: 'French Polynesia' },
        { code: 'GE', name: 'Georgia' },
        { code: 'DE', name: 'Germany' },
        { code: 'GR', name: 'Greece' },
        { code: 'GD', name: 'Grenada' },
        { code: 'GT', name: 'Guatemala' },
        { code: 'HN', name: 'Honduras' },
        { code: 'HK', name: 'Hong Kong' },
        { code: 'HU', name: 'Hungary' },
        { code: 'IN', name: 'India' },
        { code: 'ID', name: 'Indonesia' },
        { code: 'IE', name: 'Ireland' },
        { code: 'IL', name: 'Israel' },
        { code: 'IT', name: 'Italy' },
        { code: 'JM', name: 'Jamaica' },
        { code: 'JP', name: 'Japan' },
        { code: 'JO', name: 'Jordan' },
        { code: 'KE', name: 'Kenya' },
        { code: 'KR', name: 'Korea' },
        { code: 'LV', name: 'Latvia' },
        { code: 'LY', name: 'Libya' },
        { code: 'LT', name: 'Lithuania' },
        { code: 'LU', name: 'Luxemborg' },
        { code: 'MY', name: 'Malaysia' },
        { code: 'MX', name: 'Mexico' },
        { code: 'MC', name: 'Monaco' },
        { code: 'MA', name: 'Morocco' },
        { code: 'NL', name: 'Netherlands' },
        { code: 'NZ', name: 'New Zealand' },
        { code: 'NG', name: 'Nigeria' },
        { code: 'NO', name: 'Norway' },
        { code: 'PK', name: 'Pakistan' },
        { code: 'PA', name: 'Panama' },
        { code: 'PE', name: 'Peru' },
        { code: 'PH', name: 'Philippines' },
        { code: 'PL', name: 'Poland' },
        { code: 'PT', name: 'Portugal' },
        { code: 'RO', name: 'Romania' },
        { code: 'RU', name: 'Russia' },
        { code: 'SA', name: 'Saudi Arabia' },
        { code: 'SG', name: 'Singapore' },
        { code: 'SK', name: 'Slovak Republic' },
        { code: 'ZA', name: 'South Africa' },
        { code: 'ES', name: 'Spain' },
        { code: 'SE', name: 'Sweden' },
        { code: 'CH', name: 'Switzerland' },
        { code: 'TW', name: 'Taiwan' },
        { code: 'TH', name: 'Thailand' },
        { code: 'TR', name: 'Turkey' },
        { code: 'TC', name: 'Turks & Caicos Islands' },
        { code: 'UA', name: 'Ukraine' },
        { code: 'AE', name: 'United Arab Emirates' },
        { code: 'GB', name: 'United Kingdom' },
        { code: 'UY', name: 'Uruguay' },
        { code: 'US', name: 'United States' },
        { code: 'UM', name: 'U.S. Virgin Islands' },
        { code: 'VE', name: 'Venezuela' },
        { code: 'VN', name: 'Vietnam' }
      ],
      selectedCountry: 'US',
      value: '',
      price: '',
      creditCardNumber: '',
      expiryDate: ''
    }
  },
  computed: {
    states() {
      return this.getStates();
    },
  },
  methods: {
    formatCreditCardNumber(event) {
      let input = event.target.value;
      // Remove any non-numeric characters
      input = input.replace(/[^\d]/g, '');
      // Format the input as a credit card number with spaces after every fourth digit
      input = input.replace(/(\d{1,4})/g, '$1 ').trim();
      // Update the input value
      this.creditCardNumber = input;
    },
    formatExpiryDate(event) {
      let input = event.target.value;
      // Remove any non-numeric characters
      input = input.replace(/[^\d]/g, '');
      // Add a slash after the second digit if there's more than one digit
      if (input.length > 2) {
        input = input.slice(0, 2) + '/' + input.slice(2);
      }
      // Update the input value
      this.expiryDate = input;
    },
    formatPrice(event) {
      let input = event.target.value;
      // Remove any non-numeric characters except decimal point
      input = input.replace(/[^\d.]/g, '');
      // Split the input string into two parts (before and after the decimal point)
      let parts = input.split('.');
      // Add a decimal point if there isn't one already
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        input = parts[0] + '.' + parts[1];
      } else {
        input = parts[0];
      }
      // Format the input as currency with two decimal places
      parts = input.split('.');
      parts[0] = parts[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
      input = parts.join('.');
      // Update the input value
      this.price = input;
    },
    getStates() {
      switch (this.selectedCountry) {
        case 'US':
          return ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
        case 'CA':
          return ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon'];
        default:
          return [];
      }
    },
    select(event) {
      this.$emit('input', event.target.value);
    },
    submitPayment() {
      //var paymentTypes
      NarPaymentClient.init({
        //Set to 'test' or 'prod'. If nothing is set 'test' is the default.
        mode: 'test',
        //Optional Association ID (used only to get accepted payment types)
        associationId: 1,
        //Div element ID for the card number field
        cardNumberDivId: 'cardNumber',
        //Optional placeholder for the card number input
        cardNumberPlaceholder: 'Enter card number',
        //Optional configurable CSS for the card number input
        //Styling documentation: https://developer.cybersource.com/docs/cybs/en-us/digital-accept-flex/developer/all/rest/digital-accept-flex/microform-integ/Styling.html
        styles: {
          'input': {

          },
          ':focus': { 'color': 'blue' },
          ':disabled': { 'cursor': 'not-allowed' },
          'valid': { 'color': '#3c763d' },
          'invalid': { 'color': '#a94442' }
        },
        //
        onChange(cardInfo) {
          console.log(cardInfo);
          /*
            Example cardInfo
            {
              "empty": false,
              "valid": false,
              "couldBeValid": true,
              "paymentType": "Visa"
            }
          */
        }
      })
        .then(data => {
          console.log(data);
          //NarPaymentClient is initialized and for is ready for user input

          // 'paymentTypes' is an array of accepted payment types for the association
          // and can also be accessed using 'NarPaymentClient.paymentTypes' once initialized
          //paymentTypes = data.paymentTypes
        })
    },
    tokenizePayment() {
      //var token
      //var cardInfo
      NarPaymentClient.tokenizeCard({
        expirationMonth: '01',
        expirationYear: '2050'
      }).then(data => {
        console.log(data);
        //token = data.token
        //cardInfo = data.cardInfo
        //At this point the 'token' can be sent in place of card info (number and expiration)
      }).catch(error => {
        console.log('Error tokenizing CC#', error)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-group label span {
  color: red;
}

h3 {
  margin: 40px 0 0;
  font-weight: 600;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
