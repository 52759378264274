import createAuth0Client from "@auth0/auth0-spa-js";

let auth0Client = null;

export async function createAuth0() {
  auth0Client = await createAuth0Client({
    domain: "nonprod-realtors.auth0.com",
    client_id: "EasywiteLmIE3PThUIvtONoupArQbVec",
    redirect_uri: window.location.origin,
  });
  return auth0Client;
}

export function getAuth0() {
  return auth0Client;
}