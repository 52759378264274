import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import jQuery from 'jquery'
global.jQuery = jQuery
import './global.js'
import { createAuth0 } from "./auth";

createAuth0().then((auth0) => {
  new Vue({
    // ...
    created() {
      this.$auth = auth0;
    },
    // ...
  }).$mount("#app");
});


const app = createApp(App, {
    // enable experimental features
    experiments: {
      topLevelAwait: true,
      asyncComponent: true
    }
  });
  
  app.mount('#app');
